import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { get } from "../apiService";
import HomeProduct from "./HomeProduct";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import CustomerReviewCard from "./CustomerReviewCard";
import reviews from "../Assert/reviews";
import NewHomeCat from "./NewHomeCat";
import HomeWedding from "./HomeWedding";
import "../Css/NewHomeCat.css";
import RajputanaSherwani from "../Assert/new home about/Rajputana Sherwani.jpg";
import Sherwani from "../Assert/new home about/Sherwani.jpg";
import IconSec from "./IconSec";
import HomeBanner from "./HomeBanner";
import videoFile01 from "../Assert/kataria-reel1.mp4";
import videoFile02 from "../Assert/kataria-reel2.mp4";
import { Button, Modal } from 'antd';
import offer from "../Assert/Offer.jpeg";
const Home = () => {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchCategory();
    const timer = setTimeout(() => {
      setIsModalOpen(true);
    }, 3500);
    return () => clearTimeout(timer);
  }, []);

  const fetchCategory = async () => {
    try {
      const page = 1;
      const limit = 20;
      const response = await get(
        `/category.php?admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&action=fetch&page=${page}&limit=${limit}`
      );
      if (response) {
        localStorage.setItem("category", JSON.stringify(response.list));
        setCategory(response.list);
      }
    } catch (error) {
      console.log("error fetch product category");
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* <MainBanner /> */}
      {/* <ReadyToShips /> */}
      {/* <ShopCollection /> */}
      {/* <ExploreMore /> */}
      <HomeBanner />
      {/* <CircleComp category={category} /> */}
      <NewHomeCat />
      {/* <HomeWedding/> */}
      <HomeProduct />
      <IconSec />
      <section className="home-section-new-home-category">
        <div className="container">
          <div className="home-section-new-home-details-con-main">
            <div className="home-section-head-main-con">
              {/* <h2>FESTIVE hues</h2> */}
              <h3>FESTIVE HUES</h3>
            </div>
            <div className="home-section-second-main-new-category-con">
              <div className="row">
                {/* <div className="col-md-3 col-sm-6 col-6">
                      <div className="home-section-second-main-new-category-main-con">
                        <div className="home-section-second-main-new-category-image-con">
                          <img src={Sherwani} alt="Rajputana Sherwani" />
                        </div>
                        <div className="home-section-second-main-new-category-details">
                          <div className="home-section-second-main-new-category-details-con">
                            <h2>Regal Trendsetter</h2>
                            <h3>
                              <span>Wedding</span>
                            </h3>
                            <div className="home-section-first-main-new-category-con-image-details-btn-con">
                              <button>Shop Now</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <div className="home-section-second-main-new-category-main-con">
                        <div className="home-section-second-main-new-category-main-con">
                          <div className="home-section-second-main-new-category-image-con">
                            <img src={RajputanaSherwani} />
                          </div>
                          <div className="home-section-second-main-new-category-details">
                            <div className="home-section-second-main-new-category-details-con">
                              <h2>Surreal</h2>
                              <h3>
                                <span>Sangeet</span>
                              </h3>
                              <div className="home-section-first-main-new-category-con-image-details-btn-con">
                                <button>Shop Now</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <div className="home-section-second-main-new-category-main-con">
                        <div className="home-section-second-main-new-category-main-con">
                          <div className="home-section-second-main-new-category-image-con">
                            <img src={RajputanaSherwani} />
                          </div>
                          <div className="home-section-second-main-new-category-details">
                            <div className="home-section-second-main-new-category-details-con">
                              <h3>
                                <span>Haldi</span>
                              </h3>
                              <div className="home-section-first-main-new-category-con-image-details-btn-con">
                                <button>Shop Now</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <div className="home-section-second-main-new-category-main-con">
                        <div className="home-section-second-main-new-category-main-con">
                          <div className="home-section-second-main-new-category-image-con">
                            <img src={RajputanaSherwani} />
                          </div>
                          <div className="home-section-second-main-new-category-details">
                            <div className="home-section-second-main-new-category-details-con">
                              <h3>
                                <span>Reception</span>
                              </h3>
                              <div className="home-section-first-main-new-category-con-image-details-btn-con">
                                <button>Shop Now</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                <div className="col-6">
                  <div className="home-section-section-main-con-category-video-main-con">
                    <div className="home-section-section-main-con-category-video-con">
                      <video width="100%" height="50vh" muted loop controls>
                        <source src={videoFile01} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="home-section-section-main-con-category-video-main-con">
                    <div className="home-section-section-main-con-category-video-con">
                      <video width="100%" height="50vh" muted loop controls>
                        <source src={videoFile02} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Home-Section-7">
        <div className="container">
          {/* <div className="home-section-head-main-con">
            <h2>Top Selling Collections</h2>
            <h3>SHOP BY CATEGORY</h3>
          </div> */}
          <div className="Home-Section-7-testimonial">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {reviews.map((review, index) => (
                <SwiperSlide key={index}>
                  <CustomerReviewCard
                    description={review.reviews}
                    rate={review.stars}
                    name={review.user_name}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
      <Footer />

      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}  className="custom-modal" width="max-content">
            <img src={offer} alt="offer" className="popup-offer"/>
      </Modal>
    </>
  );
};

export default Home;
