import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Accordion from "react-bootstrap/Accordion";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCartContext } from '../context/addToCart';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import '../Css/ProductDetailsPages.css'
import Breadcrumbs from '../Components/Breadcrumbs'
import { ConfigProvider, Image, Modal, Space } from 'antd';
import Footer from '../Components/Footer';
import RecommendationCard from '../Components/RecommendationCard';
import CustomersReviews from '../Components/CustomersReviews';
import { Link, useParams } from 'react-router-dom';
import ProductData from "../Assert/ProductData";
import axios from 'axios';
import { get } from '../apiService';


const ProductDetailsPages = () => {
    const { slug } = useParams()
    const [loading, setLoading] = useState(false)
    const [getData, setGetData] = useState(null)
    const [price, setPrice] = useState(null);
    const [allSizeData, setAllSizeData] = useState([])
    const [allImage, setAllImages] = useState([])
    const [recommendedProduct, setRecommendedProduct] = useState([])
    const [addToCart, setAddToCart] = useState({})
    const [mainImageSrc, setMainImageSrc] = useState(null);
    const [slectedColor, setSelectedColor] = useState(null)
    const [slectedSize, setSelectedSize] = useState(null)
    const [getProductDetail, setGetProductDetail] = useState([])
    const [productID, setProductID] = useState(null)
    const [reviews, setReviews] = useState([]);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const swiperRef = useRef(null);
    console.log("all Images", allImage)
    useEffect(() => {
        fetchproductDetail()
    }, [slug])
    const handleSelectedSize = (size) => {
        setSelectedSize(size)
    }
    const handleSelectedColor = (color) => {
        setSelectedColor(color)
    }
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });
    const handleSelectedColorData = (color) => {
        console.log('color' + color);
        setAllImages(color?.image)
        setMainImageSrc(color?.image?.[0]?.images);
        setPrice(color?.product_price);
        setAllSizeData(color?.size)
        setSelectedColor(color?.color_name);
        setSelectedSize(color?.size?.[0]);
    }

    const handleThumbnailClick = (src, index) => {
        setMainImageSrc(src);
        setCurrentSlideIndex(index);
        if (swiperRef.current) {
            swiperRef.current.slideTo(index);
        }
    };

    const calculateOfferPrice = (price) => {
        return Math.round(price * (1 - 0.33));
    };

    const { handleAddCart, cartValue } = useCartContext()
    const handleaddToCart = (pdetailTocart) => {
        console.log("product detail json" + JSON.stringify(pdetailTocart))
        const data = {
            productid: pdetailTocart.product_Id,
            productName: pdetailTocart.product_name,
            colorName: slectedColor,
            product_price: price,
            fixedPrice: price,
            productImage: mainImageSrc,
            productColor: slectedColor,
            productQuantity: 1,
            productSize: slectedSize.product_size,
            product_type: pdetailTocart.product_type,
            sizeid: slectedSize.size_id,
            colorid: slectedSize.color_id
        }
        handleAddCart(data);
    }
    const fetchproductDetail = async () => {
        try {
            const response = await get(`/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=1&page=1&slugs=${slug}`);
            if (response) {
                console.log('product detail' + JSON.stringify(response))
                setGetProductDetail(response.list[0])
                setAllImages(response.list[0]?.colors?.[0]?.image)
                setAllSizeData(response.list[0]?.colors?.[0].size)
                setMainImageSrc(response?.list?.[0]?.colors?.[0]?.image?.[0]?.images)
                // setMainImageSrc(response?.list?.[0]?.colors?.[0]?.image?.[0]?.images);
                setPrice(response?.list?.[0]?.colors?.[0]?.product_price)
                setSelectedColor(response?.list?.[0]?.colors?.[0]?.color_name)
                setSelectedSize(response?.list?.[0]?.colors?.[0]?.size?.[0])
                // setSelectedColor()
                setProductID(response.list[0].product_Id)
                console.log(response.list);
                fetchProducts(response.list[0].category_id)
                fetchReviews(response.list[0].product_Id)

            }
        } catch (error) {
            console.log('Error fetching product category:', error);
        }
    };
    const fetchProducts = async (id) => {
        try {
            const current = '1'
            const limit = '5'
            let response = await get(`/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=${limit}&page=${current}&category_id=${id}`);


            if (response && response.list) {
                console.log('category fetch' + response.list);
                const newrecommend = response.list.filter((v) => v.category_id == id)
                console.log('newrecommend' + newrecommend);
                setRecommendedProduct(newrecommend)
            }
        } catch (error) {
            console.log('Error fetching product category:', error);
        } finally {
            //   setLoading(false);
        }
    };
    const fetchReviews = async (productId) => {
        setLoading(true);
        try {
            const response = await get(`/review.php?action=fetch&product_id=${productId}&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH`);
            console.log(response);
            setReviews(response.data);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const items = allImage && allImage.map(item => item.images);

    console.log("getProductDetail", getProductDetail)

    return (
        <>
            <Breadcrumbs />

            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-1 order-2 order-md-1 product-detail-left-grid'>
                            {allImage?.map((src, index) => (
                                <div
                                    key={index}
                                    className={`outer-product-detail ${mainImageSrc === src.images ? "selected-product-img" : ""}`}
                                    onClick={() => handleThumbnailClick(src.images, index)}
                                >
                                    <img
                                        className='inner-product-detail'
                                        src={src.images}
                                        alt={`Product Image ${index + 1}`}
                                    />
                                </div>
                            ))}

                        </div>
                        <div className='col-md-6 order-1 order-md-2 product-detail-middle-grid'>
                            <Swiper
                                onSwiper={(swiper) => (swiperRef.current = swiper)}
                                navigation
                                pagination={{ clickable: true }}
                                // autoplay={{ delay: 2500 }}
                                modules={[Navigation, Pagination]}
                                loop
                                initialSlide={currentSlideIndex}
                            >
                                {allImage.map((img, index) => {
                                    return (
                                        <>
                                            <SwiperSlide key={index}>
                                                <Image.PreviewGroup
                                                    items={items}
                                                >
                                                    <Image className='middle-grid-img' src={img.images} alt={`Product Image ${index + 1}`} />
                                                </Image.PreviewGroup>
                                            </SwiperSlide>
                                        </>
                                    )
                                })}
                            </Swiper>

                        </div>
                        <div className='col-md-5 order-3 order-md-3 product-detail-right-grid'>
                            <div className='product-details-page-product-name-main-con'>
                                <h4>{getProductDetail?.product_name} </h4>
                                <div className='product-details-page-product-mrp-main-con'>
                                    <div className='product-deatils-main-page-offer-price-con'>
                                        <label className='text-muted pr-1 mt-2'>M.R.P</label> <label className='product-details-price'>₹ {calculateOfferPrice(price)}</label>
                                    </div>
                                    <div className='product-deatils-main-page-main-price-con'>
                                        <label className='product-details-page-main-price'>₹ {price}</label>
                                    </div>
                                </div>
                                <div className='clothCard-product-detials-main-con-offer-per text-start'>
                                    <h4>(33% OFF)</h4>
                                </div>
                                <span className='text-muted inclusive-taxes'>
                                    inclusive of all taxes
                                </span>

                                <hr />
                            </div>
                            {/* <div className='main-color-list row pr-4 mt-2'>
                                <h5 className='mb-3'>Select Color</h5>

                                {getProductDetail?.colors?.map((v, i) => (
                                    <div className='col-md-2 col-sm-2 col-2' key={i}>
                                        <div
                                            className={`am-swatch-wrapper item swatch-option-link-layered d-flex justify-content-center align-items-center flex-column pb-2 `}
                                            onClick={() => { handleSelectedColorData(v) }}
                                        >
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_color][]"
                                                defaultValue={1152}
                                                type="radio"
                                            />
                                            <div
                                                className={`swatch-option border border-[#F0F0F0] color mb-2 mr-3 ${slectedColor === v.color_name ? "product-detail-page-default-selected-color" : ""} ${slectedColor === v.color_name ? 'border border-danger' : ''}`}
                                                style={{ backgroundColor: v.color_code }}
                                            />
                                            <div className="am-shopby-swatch-label">
                                                <span className="label">{v.name}</span>
                                                <span className="count"></span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> */}
                            {/* <hr /> */}
                            <div className='main-color-list'>
                                <h5>Select Size</h5>
                                <div className='product-detail-size-list mt-3'>
                                    {allSizeData?.map((v, i) => (
                                        <div
                                            className={`am-swatch-wrapper item swatch-option-link-layered inline-block`}
                                            onClick={() => { handleSelectedSize(v) }}
                                            key={i}
                                        >
                                            <input
                                                className="input hidden"
                                                name="amshopby[confi_size][]"
                                                defaultValue={5117}
                                                type="checkbox"
                                            />
                                            <div
                                                className={`swatch-option text py-2 px-4 mb-2 mr-2 border border-[#C5C4CC] hover:bg-223330 hover:text-white transition ${slectedSize?.size_id === v.size_id ? "product-detail-page-default-selected-size" : ""} ${slectedSize?.size_id === v.size_id ? 'border border-danger' : ''} `}
                                            >
                                                {v.product_size}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {getProductDetail?.size_chart && (
                                    <div className='product-detail-chart-size-list' onClick={showModal}>
                                        <h5>Size chart</h5>
                                    </div>
                                )}
                                <hr />
                            </div>
                            <div className='main-add-to-cart-buy-now'>
                                <button className='product-detail-buy-now' onClick={() => handleaddToCart(getProductDetail)}>
                                    ADD TO CART
                                </button>
                            </div>
                            <section>
                                <div className='row my-3 purchase-protection-row'>
                                    <div className='col-6 purchase-protection-grid mb-2'>
                                        <div className='d-flex gap-2 product-detail-page-facilites-main-con'>
                                            <svg
                                                className="flex-shrink-0"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >

                                                <circle cx="11.9989" cy="11.9989" r="10.4091" stroke="#223330" />
                                                <path
                                                    d="M14.0197 9.20548L14.1353 9.52383L14.4738 9.53457L19.0167 9.67866C19.4856 9.69354 19.6777 10.2879 19.3061 10.5744L15.7219 13.3382L15.4516 13.5466L15.5473 13.8743L16.8142 18.2129C16.9453 18.6621 16.4427 19.0292 16.0547 18.7677L12.2813 16.2239L12.0018 16.0355L11.7223 16.2239L7.94884 18.7677C7.56083 19.0292 7.05824 18.6621 7.1894 18.2129L8.45628 13.8743L8.55196 13.5466L8.28164 13.3382L4.69742 10.5744C4.32587 10.2879 4.51795 9.69354 4.98689 9.67866L9.52976 9.53457L9.86828 9.52383L9.98388 9.20548L11.5318 4.94273C11.6913 4.50361 12.3123 4.50361 12.4718 4.94273L14.0197 9.20548Z"
                                                    stroke="#223330"
                                                />
                                            </svg>

                                            <span>Quality Product</span>
                                        </div>
                                    </div>
                                    <div className='col-6 purchase-protection-grid mb-2'>
                                        <div className='d-flex gap-2 product-detail-page-facilites-main-con'>
                                            <svg
                                                className="flex-shrink-0"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >

                                                <rect x="3.5" y="5.5" width={17} height={15} fill="" stroke="#223330" />
                                                <rect x="2.5" y="3.5" width={19} height={6} fill="" stroke="#223330" />
                                                <line x1={14} y1="17.5" x2={18} y2="17.5" stroke="#223330" />
                                            </svg>

                                            <span> Free Shipping </span>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <Accordion>
                                    <Accordion.Item eventKey="1">
                                        <hr />
                                        <Accordion.Header className="container">
                                            <h6 className=" accordion-title"> Product Details</h6>
                                        </Accordion.Header>
                                        <Accordion.Body className="container">
                                            <div className='Accordion-details-main-con'>
                                                <div className='product-detail-page-product-detail-accordion'>
                                                    <label>
                                                        <div className='row'>
                                                            <div className='col-6 product-detils-page-points-head'>
                                                                <span>Product Code</span> :
                                                            </div>
                                                            <div className='col-6 product-details-page-points'>
                                                                #{getProductDetail?.product_code}
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <label>
                                                        <div className='row'>
                                                            <div className='col-6 product-detils-page-points-head'>
                                                                <span>Color</span> :
                                                            </div>
                                                            <div className='col-6 product-details-page-points'>
                                                                {getProductDetail?.color}
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <label>
                                                        <div className='row'>
                                                            <div className='col-6 product-detils-page-points-head'>
                                                                <span>Fabric</span> :
                                                            </div>
                                                            <div className='col-6 product-details-page-points'>
                                                                {getProductDetail?.fabric}
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <label>
                                                        <div className='row'>
                                                            <div className='col-6 product-detils-page-points-head'>
                                                                <span>Work/Embroidery/Print</span> :
                                                            </div>
                                                            <div className='col-6 product-details-page-points'>
                                                                {getProductDetail?.print}
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <label>
                                                        <div className='row'>
                                                            <div className='col-6 product-detils-page-points-head'>
                                                                <span>Sleeves</span> :
                                                            </div>
                                                            <div className='col-6 product-details-page-points'>
                                                                {getProductDetail?.sleeves}
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <label>
                                                        <div className='row'>
                                                            <div className='col-6 product-detils-page-points-head'>
                                                                <span>Wash Care</span>  :
                                                            </div>
                                                            <div className='col-6 product-details-page-points'>
                                                                Dry Clean Only
                                                            </div>
                                                        </div>
                                                    </label>
                                                    {/* <label> <span>Color</span> : &nbsp; {getProductDetail?.color} </label>
                                                    <label> <span>Fabric</span> : &nbsp; {getProductDetail?.fabric} </label>
                                                    <label> <span>Work/Embroidery/Print</span> : &nbsp; {getProductDetail?.print} </label>
                                                    <label> <span>Sleeves</span> : &nbsp; {getProductDetail?.sleeves} </label>
                                                    <label> <span>Wash Care</span> : &nbsp; Dry Clean Only </label> */}
                                                    {getProductDetail?.occasion && (
                                                        <label>
                                                            <div className='row'>
                                                                <div className='col-6 product-detils-page-points-head'>
                                                                    <span>Style/Tips</span> :
                                                                </div>
                                                                <div className='col-6 product-details-page-points'>
                                                                    {getProductDetail.occasion}
                                                                </div>
                                                            </div>
                                                        </label>
                                                    )}

                                                </div>
                                                <p className='mt-2'>
                                                    There may be slight color variation depending on the screen one is looking through and once you receive the product.
                                                    Inclusive of all taxes.
                                                </p>
                                                <p className='mt-2 productmain-des'>
                                                    {getProductDetail?.product_desc}
                                                </p>
                                                <p className='productmain-dispatch'>
                                                    Dispatch in 8 To 10 Days
                                                </p>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <hr />
                                        <Accordion.Header className="container">
                                            <h6 className=" accordion-title"> Shipping and Exchange</h6>
                                        </Accordion.Header>
                                        <Accordion.Body className="container">
                                            <div className='Accordion-details-main-con my-2'>
                                                <p className='product-details-faqs-para'>
                                                    We deliver our products around the world and offer different shipping options as applicable.
                                                    For more information, please feel free to contact our customer care service at <a href="mailto: Katariasstore@gmail.com">Katariasstore@gmail.com</a> or <a href="tel:+919321032490">+91 93210 32490</a>
                                                </p>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <hr />
                                </Accordion>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section className='recommendaton-section'>
                <div className='container'>
                    <h5 className='similar-product-title'>Similar Products</h5>
                    <div className=''>
                        <Swiper navigation={true} pagination={true}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Navigation, Pagination]} className="mySwiper py-3">

                            {recommendedProduct?.map((v, i) => (
                                <SwiperSlide>
                                    <Link to={`/product-detail-page/${v.slug}`}>
                                        <RecommendationCard img={v.colors?.[0]?.image?.[0]?.images} id={v.id} price={v.colors?.[0]?.product_price} name={v.product_name} />
                                    </Link>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>

            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} className="custom-modal" width="max-content">
                <img src={getProductDetail?.size_chart} alt="offer" className="popup-offer" />
            </Modal>

            <Footer />
        </>
    )
}

export default ProductDetailsPages