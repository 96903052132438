import React from 'react'
import '../Css/Footer.css'

import securePayment from "../Assert/secure-payment-India-new-1.jpeg";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Footer01 from "../Assert/FooterImages/Visa-card.png";
import Footer02 from "../Assert/FooterImages/american_express.png";
import Footer03 from "../Assert/FooterImages/cod.png";
import Footer04 from "../Assert/FooterImages/mastercard.png";
import Footer05 from "../Assert/FooterImages/rupaypng.png";
import Footer06 from "../Assert/FooterImages/upi.png";


const Footer = () => {
    return (
        <>
            <footer className='page-footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-2 col-6'>
                            <div className='footer-links'>
                                <h6 className=''> About us</h6>
                                <div className='footer-list'>
                                    <Link to='/About-us'>
                                        <label>About Us </label>
                                    </Link>
                                    <Link to='/contact-page'>
                                        <label>Contact Us</label>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2 col-6'>
                            <div className='footer-links'>
                                <h6 className=''> Policies</h6>
                                <div className='footer-list'>
                                    <Link to='/PrivacyPolicie'> <label>Privacy Policies </label> </Link>
                                    <Link to='/PaymentPolicy'> <label>Payment Policy </label> </Link>
                                    <Link to='/TermsofService'> <label>Terms & Conditions </label> </Link>
                                    <Link to='/ShippingPolicy'> <label>Shipping Policy </label> </Link>
                                    <Link to='/RefundPolicy'> <label> Exchange Policy</label> </Link>
                                    {/* <Link to='/faqs'>  <label>FAQ's</label></Link> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='footer-links'>
                                <h6 className=''>Safe & Secure Payment</h6>
                                <div className='footer-pat-icons-main-con'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='footer-pat-icons-con'>
                                                <img src={Footer01} />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='footer-pat-icons-con'>
                                                <img src={Footer02} />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='footer-pat-icons-con'>
                                                <img src={Footer03} />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='footer-pat-icons-con'>
                                                <img src={Footer04} />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='footer-pat-icons-con'>
                                                <img src={Footer05} />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='footer-pat-icons-con'>
                                                <img src={Footer06} />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className='footer-links'>
                                <h6 className=''> Follow us on</h6>
                                <div className='social-media-icon'>
                                    <label>
                                        <a href="https://www.instagram.com/katariashubli?igsh=MWQ1dGtyOTQzeTd3cA%3D%3D" target="_blank">
                                            <FaInstagram />
                                        </a>
                                    </label>
                                    <label>
                                        <a href='https://www.facebook.com/share/5AbV8V5VJQ6tCDfL/?mibextid=LQQJ4d' target='_blank' >

                                            <FaFacebookF />
                                        </a>
                                    </label>
                                    <label>
                                        <a href='https://wa.me/+919321032490' target='_blank'>
                                            <FaWhatsapp />
                                        </a>
                                    </label>
                                    <label> </label>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className='footer-map-main-con'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14308.008871868338!2d75.142465!3d15.347659000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb8d742bbaa4b89%3A0xe60b39d69259e56b!2sKataria&#39;s!5e1!3m2!1sen!2sin!4v1730097478684!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                            </div>
                        </div>
                    </div>
                    <div className='all-rights-reserved'>
                        <p className='text-muted'>
                            Copyright © 2024 Kataria's All Rights Reserved.
                        </p>
                        <p className='text-muted footer-design-by-skdm'>
                            <a className='text-muted' href='https://skdm.in/' target='_blank'>Designed by Shree Krishna Digital Marketing</a>
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer